<template>
  <div>
    <v-card outlined class="mt-3" tile>

      <v-toolbar flat class="mb-3">
        <div class="mt-5" align="left">
          <v-hover v-slot="{ hover, on, attrs }">
            <v-btn :class="{ 'blue--text': hover }" icon v-bind="attrs" v-on="on" onclick="history.back();">
              <v-icon>
                mdi-arrow-left
              </v-icon>
            </v-btn>
          </v-hover>
          <v-toolbar-title class="info--text text-uppercase font-weight-bold text--darken-1 justify-center">
            <v-icon class="mr-2 mt-n1" color="info">mdi-face</v-icon>
            Visitor Details
          </v-toolbar-title>
        </div>
        <v-spacer></v-spacer>
        <div align="right">
          <v-hover v-slot="{ hover, on, attrs }">
            <v-btn :class="{ 'blue--text': hover }" icon v-bind="attrs" v-on="on" onclick="window.print()">
              <v-icon>mdi-printer</v-icon>
            </v-btn>
          </v-hover>
        </div>
      </v-toolbar>

      <v-progress-linear indeterminate color="info" v-if="loading"></v-progress-linear>
      <v-container v-if="!loading" id="user-profile" fluid tag="section">
        <v-row justify="center">
          <v-col cols="12 mt-3 mb-3" md="12">
            <v-card>
              <template v-slot:heading>
                <div class="display-2 font-weight-light">
                  Edit Profile
                </div>

                <div class="subtitle-1 font-weight-light">
                  Complete your profile
                </div>
              </template>

              <v-form>
                <v-container class="py-0">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field label="Organization" :value="visitor_details.visitor.organization" disabled />
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field disabled label="Facility" :value="visitor_details.host_facility" />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field label="Visitor Name" class="purple-input" disabled
                        :value="visitor_details.visitor.name" />
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field label="Host Name" class="purple-input" disabled
                        :value="visitor_details.host.name" />
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field label="Email" class="purple-input" disabled
                        :value="visitor_details.visitor.email ? visitor_details.visitor.email : 'no email added'" />
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field label="National ID" class="purple-input"
                        :value="visitor_details.visitor.id_no ? visitor_details.visitor.id_no : 'no ID added'"
                        disabled />
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field label="Time In" class="purple-input" :value="visitor_details.checkin_time"
                        disabled />
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field :value="visitor_details.duration" label="Duration" class="purple-input" disabled />
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field class="purple-input" label="Time Out"
                        :value="visitor_details.checkout_time ? visitor_details.checkout_time : 'Not checked out'"
                        disabled />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card>
          </v-col>

        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="6">
            <v-card class="v-card-profile"
              avatar="https://demos.creative-tim.com/vue-material-dashboard/img/marc.aba54d65.jpg">
              <v-img :src="visitor_details.visitor.doc_image" aspect-ratio="1.7"></v-img>
              <v-card-text class="text-center">
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card class="v-card-profile"
              avatar="https://demos.creative-tim.com/vue-material-dashboard/img/marc.aba54d65.jpg">
              <v-img :src="visitor_details.visitor.live_avatar" aspect-ratio="1.7"></v-img>
              <v-card-text class="text-center">

                <h4 class="display-2 font-weight-light mb-3 black--text">
                  {{ visitor_details.visitor.name }}
                </h4>
                <p class="font-weight-light grey--text">
                  <!-- Don't be scared of the truth because we need to restart the human foundation in truth
                              And I love you like Kanye loves Kanye I love Rick Owens’ bed design but the back is...-->
                </p>

                <v-btn color="success" rounded class="mr-0 mb-5" @click="compose">
                  Add to Whitelist
                </v-btn>
                <v-dialog v-model="dialogCompose" width="500">
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Add to whitelist</span>
                    </v-card-title>
                    <v-card-text>
                      <v-form>
                        <v-text-field label="Comment" v-model="orgWhitelist.comments"></v-text-field>
                        <v-row>
                          <!-- From Date -->
                          <v-col>
                            <v-menu :close-on-content-click="closeOnContentClick" max-width="290px" min-width="290px">
                              <template v-slot:activator="{ on: menu, attrs }">
                                <v-text-field dense v-model="dateToday" label="Date" v-bind="attrs" v-on="{ ...menu }"
                                  :error-messages="apiErrors.event_date" outlined>
                                </v-text-field>
                              </template>
                              <v-date-picker v-model="dateToday" color="primary" header-color="primary"></v-date-picker>
                            </v-menu>
                          </v-col>
                          <!-- To Date -->
                          <v-col>
                            <v-menu :close-on-content-click="closeOnContentClick" max-width="290px" min-width="290px">
                              <template v-slot:activator="{ on: menu, attrs }">
                                <v-text-field dense v-model="toDate" label="Date" v-bind="attrs" v-on="{ ...menu }"
                                  :error-messages="apiErrors.event_date" outlined>
                                </v-text-field>
                              </template>
                              <v-date-picker v-model="toDate" color="primary" header-color="primary"></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="primary" @click="adminAddToWhitelist">Add</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn @click="closeModal">Close</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <!--                          <v-btn color="teal" rounded class="mr-0" mb-2 @click="adminAddToBlacklist">-->
                <!--                              Add to Blacklist-->
                <!--                          </v-btn>-->
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import OrgWhitelist from "../../../models/OrgWhitelist";
import WhitelistService from "../../../services/whitelist.service";

export default {
  data() {
    return {
      orgWhitelist: new OrgWhitelist(),
      visitor_details: [],
      loading: true,
      dialogCompose: false,
      closeOnContentClick: false,
      dateToday: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000))
        .toISOString().substr(0, 10),
      toDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000))
        .toISOString().substr(0, 10),
      apiErrors: [],

    };
  },

  props: {
    item: Object,
  },

  methods: {
    getVisitorDetails() {
      if (this.$route.params.visitor) {
        const id = this.$route.params.visitor;
        this.visitor_details = this.$store.getters["reports/findReport"](id);
        this.loading = false;
      }
    },

    compose() {
      this.dialogCompose = true
    },

    adminAddToWhitelist() {
      this.orgWhitelist.from_date = this.dateToday;
      this.orgWhitelist.to_date = this.toDate;
      this.orgWhitelist.guest_phone = parseInt(this.visitor_details.visitor.phone);

      console.log(this.orgWhitelist);
      WhitelistService.storeOrgWhitelist(this.orgWhitelist).then(
        (response) => {
          if (response.status === 200) {
            this.$store.dispatch("alert/success", "Whitelist added");
            this.loading = false;
            this.closeModal();
          }
        },
        (error) => {
          if (error.response.status === 422) {
            this.apiErrors = error.response.data.errors
            console.log(error.response.data.errors)
            this.$store.dispatch("alert/error", error.response.data.message);
            this.loading = false;
          }

          this.$store.dispatch("alert/error", error.response.data.message);
        }
      );
    },

    adminAddToBlacklist() {

    },

    closeModal() {
      this.orgWhitelist.comments = null;
      this.dialogCompose = false;
    }

  },

  created() {
    this.$nextTick(() => {
      this.getVisitorDetails();
    })
  }

}
</script>
